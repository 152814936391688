/* tslint:disable */
/* eslint-disable */
/**
 * XouraApi
 * Xoura API (Internal)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated.
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  BadRequestErrorResponseContent,
  ForbiddenErrorResponseContent,
  GetAlertItemResponseContent,
  GetAlertItemsResponseContent,
  GetBillingItemResponseContent,
  GetBillingItemsResponseContent,
  GetChargeItemResponseContent,
  GetChargeItemsResponseContent,
  GetOccupancyItemResponseContent,
  GetOccupancyItemsResponseContent,
  GetOrganisationsResponseContent,
  GetReviewItemResponseContent,
  GetReviewItemsResponseContent,
  GetSalesItemResponseContent,
  GetSalesItemsResponseContent,
  GetTermItemResponseContent,
  GetTermItemsResponseContent,
  GetUserDataItemResponseContent,
  GetUserDataItemsResponseContent,
  InternalFailureErrorResponseContent,
  NotFoundErrorResponseContent,
  SignInRequestContent,
  SignUpRequestContent,
  UnauthorizedErrorResponseContent,
  XeroWebhookRequestContent,
} from '../models';
import {
    BadRequestErrorResponseContentFromJSON,
    BadRequestErrorResponseContentToJSON,
    ForbiddenErrorResponseContentFromJSON,
    ForbiddenErrorResponseContentToJSON,
    GetAlertItemResponseContentFromJSON,
    GetAlertItemResponseContentToJSON,
    GetAlertItemsResponseContentFromJSON,
    GetAlertItemsResponseContentToJSON,
    GetBillingItemResponseContentFromJSON,
    GetBillingItemResponseContentToJSON,
    GetBillingItemsResponseContentFromJSON,
    GetBillingItemsResponseContentToJSON,
    GetChargeItemResponseContentFromJSON,
    GetChargeItemResponseContentToJSON,
    GetChargeItemsResponseContentFromJSON,
    GetChargeItemsResponseContentToJSON,
    GetOccupancyItemResponseContentFromJSON,
    GetOccupancyItemResponseContentToJSON,
    GetOccupancyItemsResponseContentFromJSON,
    GetOccupancyItemsResponseContentToJSON,
    GetOrganisationsResponseContentFromJSON,
    GetOrganisationsResponseContentToJSON,
    GetReviewItemResponseContentFromJSON,
    GetReviewItemResponseContentToJSON,
    GetReviewItemsResponseContentFromJSON,
    GetReviewItemsResponseContentToJSON,
    GetSalesItemResponseContentFromJSON,
    GetSalesItemResponseContentToJSON,
    GetSalesItemsResponseContentFromJSON,
    GetSalesItemsResponseContentToJSON,
    GetTermItemResponseContentFromJSON,
    GetTermItemResponseContentToJSON,
    GetTermItemsResponseContentFromJSON,
    GetTermItemsResponseContentToJSON,
    GetUserDataItemResponseContentFromJSON,
    GetUserDataItemResponseContentToJSON,
    GetUserDataItemsResponseContentFromJSON,
    GetUserDataItemsResponseContentToJSON,
    InternalFailureErrorResponseContentFromJSON,
    InternalFailureErrorResponseContentToJSON,
    NotFoundErrorResponseContentFromJSON,
    NotFoundErrorResponseContentToJSON,
    SignInRequestContentFromJSON,
    SignInRequestContentToJSON,
    SignUpRequestContentFromJSON,
    SignUpRequestContentToJSON,
    UnauthorizedErrorResponseContentFromJSON,
    UnauthorizedErrorResponseContentToJSON,
    XeroWebhookRequestContentFromJSON,
    XeroWebhookRequestContentToJSON,
} from '../models';

export interface GetAlertItemRequest {
    occupancyId: string;
    alertId: string;
}

export interface GetAlertItemsRequest {
    occupancyId: string;
}

export interface GetBillingItemRequest {
    occupancyId: string;
    billingId: string;
}

export interface GetBillingItemsRequest {
    occupancyId: string;
}

export interface GetChargeItemRequest {
    occupancyId: string;
    chargeId: string;
}

export interface GetChargeItemsRequest {
    occupancyId: string;
}

export interface GetOccupancyItemRequest {
    occupancyId: string;
}

export interface GetOccupancyItemsRequest {
    legalContactId?: string;
    currentOccupancyId?: string;
}


export interface GetReviewItemRequest {
    occupancyId: string;
    reviewId: string;
}

export interface GetReviewItemsRequest {
    occupancyId: string;
}

export interface GetSalesItemRequest {
    occupancyId: string;
    salesId: string;
}

export interface GetSalesItemsRequest {
    occupancyId: string;
}

export interface GetTermItemRequest {
    occupancyId: string;
    termId: string;
}

export interface GetTermItemsRequest {
    occupancyId: string;
}

export interface GetUserDataItemRequest {
    occupancyId: string;
    userDataId: string;
}

export interface GetUserDataItemsRequest {
    occupancyId: string;
}

export interface SignInRequest {
    signInRequestContent: SignInRequestContent;
}

export interface SignUpRequest {
    signUpRequestContent: SignUpRequestContent;
}

export interface XeroWebhookRequest {
    xeroWebhookRequestContent: XeroWebhookRequestContent;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     * 
     */
    async getAlertItemRaw(requestParameters: GetAlertItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAlertItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getAlertItem.');
        }

        if (requestParameters.alertId === null || requestParameters.alertId === undefined) {
            throw new runtime.RequiredError('alertId','Required parameter requestParameters.alertId was null or undefined when calling getAlertItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/alert/{occupancyId}/{alertId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"alertId"}}`, encodeURIComponent(String(requestParameters.alertId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAlertItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getAlertItem(requestParameters: GetAlertItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAlertItemResponseContent> {
        const response = await this.getAlertItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getAlertItemsRaw(requestParameters: GetAlertItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAlertItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getAlertItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/alert/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAlertItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getAlertItems(requestParameters: GetAlertItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAlertItemsResponseContent> {
        const response = await this.getAlertItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getBillingItemRaw(requestParameters: GetBillingItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBillingItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getBillingItem.');
        }

        if (requestParameters.billingId === null || requestParameters.billingId === undefined) {
            throw new runtime.RequiredError('billingId','Required parameter requestParameters.billingId was null or undefined when calling getBillingItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/billing/{occupancyId}/{billingId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"billingId"}}`, encodeURIComponent(String(requestParameters.billingId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBillingItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getBillingItem(requestParameters: GetBillingItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBillingItemResponseContent> {
        const response = await this.getBillingItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getBillingItemsRaw(requestParameters: GetBillingItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBillingItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getBillingItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/billing/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBillingItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getBillingItems(requestParameters: GetBillingItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBillingItemsResponseContent> {
        const response = await this.getBillingItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getChargeItemRaw(requestParameters: GetChargeItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChargeItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getChargeItem.');
        }

        if (requestParameters.chargeId === null || requestParameters.chargeId === undefined) {
            throw new runtime.RequiredError('chargeId','Required parameter requestParameters.chargeId was null or undefined when calling getChargeItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/charge/{occupancyId}/{chargeId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"chargeId"}}`, encodeURIComponent(String(requestParameters.chargeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChargeItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getChargeItem(requestParameters: GetChargeItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChargeItemResponseContent> {
        const response = await this.getChargeItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getChargeItemsRaw(requestParameters: GetChargeItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChargeItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getChargeItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/charge/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChargeItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getChargeItems(requestParameters: GetChargeItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChargeItemsResponseContent> {
        const response = await this.getChargeItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getOccupancyItemRaw(requestParameters: GetOccupancyItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOccupancyItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getOccupancyItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/occupancy/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOccupancyItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getOccupancyItem(requestParameters: GetOccupancyItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOccupancyItemResponseContent> {
        const response = await this.getOccupancyItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getOccupancyItemsRaw(requestParameters: GetOccupancyItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOccupancyItemsResponseContent>> {
        const queryParameters: any = {};

        if (requestParameters.legalContactId !== undefined) {
            queryParameters['legalContactId'] = requestParameters.legalContactId;
        }

        if (requestParameters.currentOccupancyId !== undefined) {
            queryParameters['currentOccupancyId'] = requestParameters.currentOccupancyId;
        }


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/occupancy`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOccupancyItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getOccupancyItems(requestParameters: GetOccupancyItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOccupancyItemsResponseContent> {
        const response = await this.getOccupancyItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getOrganisationsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOrganisationsResponseContent>> {
        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/security/organisation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOrganisationsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getOrganisations(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOrganisationsResponseContent> {
        const response = await this.getOrganisationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getReviewItemRaw(requestParameters: GetReviewItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReviewItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getReviewItem.');
        }

        if (requestParameters.reviewId === null || requestParameters.reviewId === undefined) {
            throw new runtime.RequiredError('reviewId','Required parameter requestParameters.reviewId was null or undefined when calling getReviewItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/review/{occupancyId}/{reviewId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"reviewId"}}`, encodeURIComponent(String(requestParameters.reviewId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReviewItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getReviewItem(requestParameters: GetReviewItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReviewItemResponseContent> {
        const response = await this.getReviewItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getReviewItemsRaw(requestParameters: GetReviewItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetReviewItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getReviewItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/review/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetReviewItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getReviewItems(requestParameters: GetReviewItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetReviewItemsResponseContent> {
        const response = await this.getReviewItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getSalesItemRaw(requestParameters: GetSalesItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSalesItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getSalesItem.');
        }

        if (requestParameters.salesId === null || requestParameters.salesId === undefined) {
            throw new runtime.RequiredError('salesId','Required parameter requestParameters.salesId was null or undefined when calling getSalesItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/sales/{occupancyId}/{salesId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"salesId"}}`, encodeURIComponent(String(requestParameters.salesId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalesItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getSalesItem(requestParameters: GetSalesItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSalesItemResponseContent> {
        const response = await this.getSalesItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getSalesItemsRaw(requestParameters: GetSalesItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSalesItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getSalesItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/sales/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSalesItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getSalesItems(requestParameters: GetSalesItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSalesItemsResponseContent> {
        const response = await this.getSalesItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getTermItemRaw(requestParameters: GetTermItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTermItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getTermItem.');
        }

        if (requestParameters.termId === null || requestParameters.termId === undefined) {
            throw new runtime.RequiredError('termId','Required parameter requestParameters.termId was null or undefined when calling getTermItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/term/{occupancyId}/{termId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"termId"}}`, encodeURIComponent(String(requestParameters.termId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTermItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getTermItem(requestParameters: GetTermItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTermItemResponseContent> {
        const response = await this.getTermItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getTermItemsRaw(requestParameters: GetTermItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetTermItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getTermItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/term/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTermItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getTermItems(requestParameters: GetTermItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetTermItemsResponseContent> {
        const response = await this.getTermItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getUserDataItemRaw(requestParameters: GetUserDataItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserDataItemResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getUserDataItem.');
        }

        if (requestParameters.userDataId === null || requestParameters.userDataId === undefined) {
            throw new runtime.RequiredError('userDataId','Required parameter requestParameters.userDataId was null or undefined when calling getUserDataItem.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/userData/{occupancyId}/{userDataId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))).replace(`{${"userDataId"}}`, encodeURIComponent(String(requestParameters.userDataId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDataItemResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getUserDataItem(requestParameters: GetUserDataItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserDataItemResponseContent> {
        const response = await this.getUserDataItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async getUserDataItemsRaw(requestParameters: GetUserDataItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserDataItemsResponseContent>> {
        if (requestParameters.occupancyId === null || requestParameters.occupancyId === undefined) {
            throw new runtime.RequiredError('occupancyId','Required parameter requestParameters.occupancyId was null or undefined when calling getUserDataItems.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};



        const response = await this.request({
            path: `/userData/{occupancyId}`.replace(`{${"occupancyId"}}`, encodeURIComponent(String(requestParameters.occupancyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserDataItemsResponseContentFromJSON(jsonValue));
    }

    /**
     * 
     */
    async getUserDataItems(requestParameters: GetUserDataItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserDataItemsResponseContent> {
        const response = await this.getUserDataItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     */
    async signInRaw(requestParameters: SignInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signInRequestContent === null || requestParameters.signInRequestContent === undefined) {
            throw new runtime.RequiredError('signInRequestContent','Required parameter requestParameters.signInRequestContent was null or undefined when calling signIn.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        const response = await this.request({
            path: `/security/signin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignInRequestContentToJSON(requestParameters.signInRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async signIn(requestParameters: SignInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signInRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async signUpRaw(requestParameters: SignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.signUpRequestContent === null || requestParameters.signUpRequestContent === undefined) {
            throw new runtime.RequiredError('signUpRequestContent','Required parameter requestParameters.signUpRequestContent was null or undefined when calling signUp.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        const response = await this.request({
            path: `/security/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignUpRequestContentToJSON(requestParameters.signUpRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async signUp(requestParameters: SignUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.signUpRaw(requestParameters, initOverrides);
    }

    /**
     * 
     */
    async xeroWebhookRaw(requestParameters: XeroWebhookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.xeroWebhookRequestContent === null || requestParameters.xeroWebhookRequestContent === undefined) {
            throw new runtime.RequiredError('xeroWebhookRequestContent','Required parameter requestParameters.xeroWebhookRequestContent was null or undefined when calling xeroWebhook.');
        }

        const queryParameters: any = {};


        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        const response = await this.request({
            path: `/webhooks/xero`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: XeroWebhookRequestContentToJSON(requestParameters.xeroWebhookRequestContent),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async xeroWebhook(requestParameters: XeroWebhookRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.xeroWebhookRaw(requestParameters, initOverrides);
    }

}

